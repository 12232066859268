





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































 @import '~@/assets/styles/helpers/_variables.scss';

.v-card { border-radius: 16px !important; }

 .optionsButton {
   color: white;
   padding: 6px;
   background-color: $colorAccent;
   border-radius: 16px;
   margin: 0 auto;
 }
 div.limit-row {
   max-height: 55px;
   @media(max-width: 768px) {
     max-height: initial;
   }
   .v-input::v-deep .v-input__control .v-input__slot {
       min-height: 45px;
   }
   .v-input::v-deep .v-input__append-inner{
     margin-top: 10px
   }
   .v-input::v-deep .v-input__append-outer{
     margin-top: 12px
   }
   .checkbox::v-deep .v-input__control .v-input__slot{
     margin-bottom: 0;
   }
 }
 .checkbox{
   max-height: 25px;
 }

 .v-text-field.v-text-field--enclosed .v-text-field__details{
   margin: 0;
   padding: 10px 10px;
 }
 .v-application--is-ltr .v-messages{
   color: red
 }
